import GoogleAnalytics from './utils/GoogleAnalytics.js';

// Break out of (i)frame if needed. This is needed in some cases since we rely
// on (i)frames in the old code base and webeasy.
if (window.top.location !== window.location)
    window.top.location = window.location;

document.addEventListener("DOMContentLoaded", (event) => {
    let googleAnalytics = null;
    let init            = document.getElementById('lcmk-init');
    let data            = null;

    // Didn't find an init element, bail out
    if (init === null)
        return;

    try {
        data = JSON.parse(init.textContent);
    } catch (error) {
        console.warn("Failed to parse the lcmk-init JSON object. Typo?");
        return;
    }

    // Load Google Analytics if we find a tracking id
    if (data.hasOwnProperty('gaTrackingId')) {
        googleAnalytics = new GoogleAnalytics(data.gaTrackingId);
        googleAnalytics.load();
    }
});
