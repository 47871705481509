/**
 * Wrapper class around Google Analytics so we can decide
 * when and where it should initiate.
 */
class GoogleAnalytics
{
    /**
     * Constructor
     *
     * @return  void
     */
    constructor(trackingId)
    {
        this.trackingId = trackingId;
    }

    /**
     * This will load the Google Analyics code and send
     * a pageview event.
     *
     * @return  boolean
     */
    load()
    {
        try {
            if (this.trackingId === undefined) throw false;
            if (this.trackingId === null     ) throw false;
            if (this.trackingId === ''       ) throw false;
        } catch (ex) {
            console.error("Google Analytics tracking ID is not set");

            return false;
        }

        (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
        (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
        m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
        })(window,document,'script','//www.google-analytics.com/analytics.js','ga');

        ga('create', this.trackingId, 'auto');
        ga('send', 'pageview');

        return true;
    }
}

export default GoogleAnalytics;
